import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'recompose';
import get from 'lodash.get';

import {
  withHelmet,
  withTemplate,
} from 'Protons';
import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';

import { getNativeAd } from 'SharedPartials/getNativeAd';
import { getBreadcrumbs } from 'SharedPartialsLocal/getBreadcrumbs';
import { Heading } from '@autovia-uk/polaris-components/components/molecules/Heading';

import { getReviewMultipageNavigation } from 'SharedPartialsLocal/getReviewMultipageNavigation';

import { getNextSteps } from 'SharedPartialsLocal/getNextSteps';
import { getPostMeta } from 'SharedPartialsLocal/getPostMeta';
import { getReportCard } from 'SharedPartialsLocal/getReportCard';
import { getSocialSharingButtons } from 'SharedPartialsLocal/getSocialSharingButtons';
import { getTabs } from 'SharedPartialsLocal/getTabs';
import { getTags } from 'SharedPartialsLocal/getTags';

import { getArticleGroup } from 'SharedPartialsLocal/getArticleGroup';

import {
  getArticleGroupContent,
  getRelatedContent,
  getSimilarProductContent,
} from 'Helpers';

// Template styles and imports
import 'Styles/components/templates/_Review.scss';

const OwnerReview = (props) => {
  const {
    layoutData: {
      page: {
        title,
        url,
        breadcrumbs,
        hideTitle,
        kicker,
        tags,
        isSponsored,
        associatedContent,
        ownerReviewData,
      },
    },
    dataLayer,
    config: {
      siteUrl,
      social: {
        share: {
          platforms: socialSharingPlatforms,
        },
      },
    },
  } = props;

  const { layoutData: { page } } = props;
  const multipage = getRelatedContent(associatedContent, 'relatedPages');

  return (
    <>
      <div className="polaris__body">
        <main className="polaris__main" id="main">
          <div className="polaris__content">
            { getBreadcrumbs(breadcrumbs, isSponsored, kicker) }
            {!hideTitle && (
              <Heading size={1} extraClassNames={{ '-content-title': true }}>
                {title}
              </Heading>
            )}
            {getPostMeta(page, false, siteUrl, dataLayer)}
          </div>
          <div className="polaris__content">
            {getReviewMultipageNavigation(multipage, url)}
            {getReportCard({ props })}
          </div>
          <div className="polaris__content polaris__simple-grid -body-copy">
            <div className="polaris__simple-grid--main">
              {ownerReviewData && getTabs(ownerReviewData)}
              {getNextSteps({ props: page, siteUrl })}
              <div className="polaris__content-bottom">
                {getTags(tags, false)}
                {getSocialSharingButtons({
                  title,
                  siteUrl,
                  url,
                  socialSharingPlatforms,
                  extraClassNames: { 'polaris__review--social': true },
                })}
              </div>
            </div>
          </div>
        </main>
      </div>
      <div className="polaris__content">
        {getArticleGroup({
          title: 'Have you considered?',
          content: getSimilarProductContent(associatedContent),
          extraClassNames: { 'polaris__review--consider': true },
          articleCardProps: { datePosition: 'meta', showRating: true, excerpt: null },
        })}
        {getArticleGroup({
          title: 'Most Popular',
          content: getArticleGroupContent(associatedContent, 'mostPopular', {
            component: [
              <React.Fragment key="native_article">
                {getNativeAd({
                  id: 'native_article',
                  targeting: {
                    position: 'native_article',
                    placement: 'native_article',
                    refresh: 'no',
                  },
                })}
              </React.Fragment>,
            ],
          }),
          extraClassNames: { 'polaris__review--most-popular': true },
          articleCardProps: { isHeading: false },
        })}
      </div>
    </>
  );
};

OwnerReview.propTypes = {
  layoutData: PropTypes.shape({
    page: PropTypes.objectOf().isRequired,
  }).isRequired,
  metaData: PropTypes.shape({
    pageTitle: PropTypes.string.isRequired,
    pageDescription: PropTypes.string.isRequired,
  }).isRequired,
  config: PropTypes.shape({
    newsletterConfig: PropTypes.shape(),
    siteUrl: PropTypes.string.isRequired,
    social: PropTypes.shape({
      share: PropTypes.shape({
        platforms: PropTypes.arrayOf(PropTypes.shape({
          label: PropTypes.string,
          platform: PropTypes.string,
        })),
      }),
    }),
    header: PropTypes.shape({
      logoImage: PropTypes.string,
    }),
  }).isRequired,
  dataLayer: PropTypes.shape({
    product: PropTypes.string,
    productFamily: PropTypes.string,
    make: PropTypes.string,
    productFamilyShortName: PropTypes.string,
    productShortName: PropTypes.string,
  }),
  location: PropTypes.shape({
    search: PropTypes.string,
    pathname: PropTypes.string,
  }),
};

OwnerReview.defaultProps = {
  dataLayer: {},
  location: {
    search: '',
    pathname: '',
  },
};

const MemoOwnerReview = memo(OwnerReview);

export default compose(
  withTemplate,
  withPolaris,
  withHelmet('review'),
)(MemoOwnerReview);
