import React from 'react';
import PropTypes from 'prop-types';

import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';
import { LandingPageBanner } from 'Molecules/LandingPageBanner';

import Pros from './Pros/Pros';
import valuationToolWidgetData from './Data/index.json';

import './_style.scss';

export const addGATracking = (data) => {
  const {
    vrm,
  } = data;

  window.dataLayer.push({
    event: 'valuation.button_click',
    'valuation.step': '1',
    'valuation.vrm': vrm,
  });
};

const ValuationToolWidget = (props) => {
  const {
    context: {
      cssPrefix,
    },
  } = props;

  const { headerCopy } = valuationToolWidgetData;

  return (
    <div className={`${cssPrefix}__valuation__landing-page`}>
      <LandingPageBanner
        {... props}
        bannerTitle={headerCopy.title}
        bannerSubtitle={headerCopy.subtitle}
        regPlateFormProps={{
          ctaUrl: '/car-valuation?ip&vrm=[vrm]',
          ctaLabel: headerCopy.linkLabel,
          inputLabel: headerCopy.inputLabel,
          inputPlaceholder: headerCopy.inputPlaceholder,
          submitAction: addGATracking,
          submitTypeIsButton: true,
        }}
      />
      <div className={`${cssPrefix}__valuation__landing-page__content`}>
        <div className={`${cssPrefix}__valuation__landing-page__description`}>
          {headerCopy.desc}
        </div>
        <Pros />
      </div>
    </div>
  );
};

ValuationToolWidget.propTypes = {
  context: PropTypes.shape(),
};

ValuationToolWidget.defaultProps = {
  context: {},
};

export default withPolaris(ValuationToolWidget);
