/**
 * @render react
 * @name Header
 */

import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getMappedLayoutType } from 'Helpers';
import { formatURLforPPC } from 'SharedHelpers/formatURLforPPC';

import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';

import { Image } from '@autovia-uk/polaris-components/components/atoms/Image';
import { Button } from '@autovia-uk/polaris-components/components/atoms/Button';
import { Link } from '@autovia-uk/polaris-components/components/molecules/Link';
import { Navigation } from '@autovia-uk/polaris-components/components/molecules/Navigation'; // Button, NavigationItem (Icon and Link)
import { ProductSelector } from '@autovia-uk/polaris-components/components/molecules/ProductSelector'; // Button, Select, List
import { SimpleSearch } from '@autovia-uk/polaris-components/components/molecules/SimpleSearch'; // Button, Input, Form, Label

import { ProductSelectorQuery } from './ProductSelectorQuery.graphql';

import './_style.scss';

const Header = (props) => {
  const {
    context: {
      cssPrefix,
      header: {
        navigation,
      },
      globalSettings: {
        promoBoxSettings,
      },
      branding: {
        logoImage,
      },
    },
    client,
    config,
  } = props;
  const searchRef = React.createRef();
  const [isOpen, setOpen] = useState(false);
  const [isSearchOpen, setSearchOpen] = useState(false);

  const mappedLayoutType = getMappedLayoutType(props);
  const isLandingPage = mappedLayoutType === 'landingPage';

  const onClick = () => {
    setOpen(!isOpen);
    document.documentElement.classList.toggle('-menu-open');
  };

  const onSearchClick = () => {
    setSearchOpen(!isSearchOpen);
    setOpen(false);
    document.documentElement.classList.remove('-menu-open');
  };

  const onSearchSubmit = () => {
    onSearchClick();
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && isSearchOpen) {
      if (searchRef && searchRef.current) {
        const inputEl = searchRef.current.querySelector('input[type="search"]');
        setTimeout(() => {
          inputEl.focus();
        }, 0);
      }
    }
  }, [isSearchOpen]);

  const { loading: makeModelDataLoading, data: makeModelData } = useQuery(ProductSelectorQuery, {
    ssr: false,
    client,
  });

  // remove empty objects from navigation
  const filteredNavigation = navigation && navigation.filter(value => Object.keys(value).length !== 0);
  const subscriptionButtonText = 'Try 6 issues for £1';

  return (
    <>
      <div className="screen-reader-text">
        <a href="#main">Skip to Content</a>
        <a href="#footer">Skip to Footer</a>
      </div>
      <header
        className={classNames({
          [`${cssPrefix}__header`]: true,
          [`${cssPrefix}__header-landing`]: isLandingPage,
        })}
        id="header"
      >
        <div
          className={classNames({
            [`${cssPrefix}__header--inner`]: true,
            '-is-menu-open': isOpen,
            '-is-search-open': isSearchOpen,
          })}
        >
          <div
            className={classNames({
              [`${cssPrefix}__header--logo`]: true,
              [`${cssPrefix}__header--logo-landing`]: isLandingPage,
            })}
          >
            <Link href="/">
              <Image
                src={logoImage.src}
                height={logoImage.height}
                width={logoImage.width}
                lazyLoad={false}
              />
            </Link>
          </div>

          {!isLandingPage && (
            <div
              className={classNames({
                [`${cssPrefix}__header--navigation-search`]: true,
              })}
            >
              {filteredNavigation && (
                <Navigation
                  id="polaris__top-menu"
                  content={filteredNavigation}
                  onButtonClick={onClick}
                  navigationClassName={`${cssPrefix}__header--navigation`}
                  buttonClassName={`${cssPrefix}__header--menu-button`}
                  subMenuIcon={`${cssPrefix}__ico-down`}
                  responsive
                  activeWildcard={false}
                  subMenuClickBreakpoint="tablet"
                />
              )}

              <div
                className={classNames({
                  [`${cssPrefix}__header--search`]: true,
                })}
                ref={searchRef}
              >
                <SimpleSearch
                  label=""
                  placeholder="What are you looking for"
                  url="/search"
                  submitLabel=""
                  onSubmit={onSearchSubmit}
                />
                <Button
                  type="button"
                  label="Search"
                  srLabel="Search"
                  onClick={onSearchClick}
                  extraClassNames={{
                    [`${cssPrefix}__header--search-button`]: true,
                  }}
                />
              </div>
            </div>
          )}

          {!isLandingPage && promoBoxSettings && promoBoxSettings.ctaUrl && (
            <div className={`${cssPrefix}__header--subscribe`}>
              <Link
                loadStyles={false}
                href={
                  promoBoxSettings.ctaUrl
                  && formatURLforPPC({
                    url: promoBoxSettings.ctaUrl,
                    ppc: promoBoxSettings.subscriptionHeader,
                  })
                }
                extraClassNames={{
                  [`${cssPrefix}__button`]: true,
                }}
                onClick={() => {
                  if (typeof window !== 'undefined') {
                    window.dataLayer.push({
                      event: 'subscription.button_click',
                      'subscription.placement': 'Header',
                      'offer.text': subscriptionButtonText,
                    });
                  }
                }}
              >
                {subscriptionButtonText}
              </Link>
            </div>
          )}
        </div>

        {isLandingPage
          ? (<div className={`${cssPrefix}__header--landing`} />)
          : (
            <div className={`${cssPrefix}__header--product-selector--wrapper`}>
              <div className={`${cssPrefix}__content ${cssPrefix}__content-padding`}>
                <ProductSelector
                  extraClassNames={{ [`${cssPrefix}__header--product-selector`]: true }}
                  inputs={makeModelDataLoading ? get(config, 'globalSettings.productData', []) : get(makeModelData, 'getSettings.productData', [])}
                />
              </div>
            </div>
          )
        }

      </header>
    </>
  );
};

Header.propTypes = {
  context: PropTypes.shape().isRequired,
  client: PropTypes.shape().isRequired,
  config: PropTypes.shape(),
};

Header.defaultProps = {
  config: {},
};

export default withPolaris(Header);
