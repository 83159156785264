import React from 'react';
import PropTypes from 'prop-types';
import { getSocialSharingButtons } from 'SharedPartialsLocal/getSocialSharingButtons';
import { getTags } from 'SharedPartialsLocal/getTags';

const SpecOutro = ({
  socialSharingPlatforms,
  tags,
  title,
  url,
  siteUrl,
}) => (
  <div className="polaris__content polaris__simple-grid--full -body-copy">
    <div className="polaris__content-bottom">
      {getTags(tags, false)}
      {getSocialSharingButtons({
        socialSharingPlatforms,
        title,
        siteUrl,
        url,
        extraClassNames: { 'polaris__spec--social': true },
      })}
    </div>
  </div>
);


SpecOutro.propTypes = {
  socialSharingPlatforms: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  siteUrl: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
  })),
  title: PropTypes.string,
  url: PropTypes.string,
};

SpecOutro.defaultProps = {
  tags: [],
  title: '',
  url: '',
};

export default SpecOutro;
