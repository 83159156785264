import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';
import { RegPlateForm } from '@autovia-uk/polaris-components/components/molecules/RegPlateForm';

import 'Styles/components/molecules/_LandingPageBanner.scss';

/**
 * @name LandingPageBanner
 * @param {string} cssPrefix
 * @param {Object} extraClassNames
 * @param {string} bannerTitle
 * @param {string} bannerSubtitle
 * @param {object} regPlateFormProps
 * @returns {JSX.Element}
 * @constructor
 */
const LandingPageBanner = ({
  bannerTitle,
  bannerSubtitle,
  context: {
    cssPrefix,
  },
  extraClassNames,
  regPlateFormProps,
}) => (
  <div className={classNames({
    [`${cssPrefix}__landing-page-banner`]: true,
    ...extraClassNames,
  })}
  >
    <div className={`${cssPrefix}__landing-page-banner--background`} />
    <div className={`${cssPrefix}__landing-page-banner--content-wrapper`}>
      <div className={`${cssPrefix}__landing-page-banner--content`}>
        <div className={`${cssPrefix}__landing-page-banner--meta`}>
          <div className={`${cssPrefix}__landing-page-banner--title`}>{bannerTitle}</div>
          <div className={`${cssPrefix}__landing-page-banner--subtitle`}>{bannerSubtitle}</div>
        </div>
        {regPlateFormProps && (
          <div className={`${cssPrefix}__landing-page-banner--regplateform`}>
            <RegPlateForm {...regPlateFormProps} />
          </div>
        )}
      </div>
    </div>
  </div>
);

LandingPageBanner.propTypes = {
  bannerSubtitle: PropTypes.string,
  bannerTitle: PropTypes.string,
  context: PropTypes.shape(),
  extraClassNames: PropTypes.shape(),
  regPlateFormProps: PropTypes.shape({
    ctaLabel: PropTypes.string,
    ctaUrl: PropTypes.string,
    extraClassNames: PropTypes.shape(),
    inputLabel: PropTypes.string,
    inputPlaceholder: PropTypes.string,
    submitAction: PropTypes.func,
    submitTypeIsButton: PropTypes.bool,
  }),
};

LandingPageBanner.defaultProps = {
  bannerSubtitle: '',
  bannerTitle: '',
  context: {},
  extraClassNames: {},
  regPlateFormProps: {
    ctaLabel: '',
    ctaUrl: '',
    extraClassNames: {},
    inputLabel: '',
    inputPlaceholder: '',
    submitAction: () => {},
    submitTypeIsButton: true,
  },
};

export default withPolaris(LandingPageBanner);
