import React from 'react';
import PropTypes from 'prop-types';

import rules from 'Config/localInjectionRules/injectInlineAdsSpecsList';
import injectionEngine from '@autovia-uk/injection-engine';

import { Ad } from '@autovia-uk/polaris-components/components/atoms/Ad';
import { Link } from '@autovia-uk/polaris-components/components/molecules/Link';
import { Table } from '@autovia-uk/polaris-components/components/organisms/Table';

export const SpecListItem = ({
  listItem,
}) => {
  const {
    name,
    items,
  } = listItem;

  if (listItem.fuelType && listItem.fuelType === 'Electric') {
    return (
      <div className="polaris__content polaris__simple-grid--full -body-copy">
        <Table
          mode="stack"
          extraClassNames={{
            '-specs': true,
            '-electric': true,
          }}
        >
          <table>
            <caption>{name}</caption>
            <thead>
              <tr>
                <th>MODEL</th>
                <th>FUEL</th>
                <th>BATTERY CAPACITY</th>
                <th>RANGE</th>
                <th>0-62MPH</th>
                <th>TOP SPEED</th>
                <th>BHP</th>
                <th>PRICE</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, itemElectricIndex) => (
                <tr key={`itemElectric_${itemElectricIndex}`}>
                  <td><Link href={item.path} extraClassNames={{ 'polaris__car-name--link': true }}>{item.name}</Link></td>
                  <td>{item.fuel ? item.fuel.value : ''}</td>
                  <td>{item.batteryCapacity ? item.batteryCapacity.value : ''}</td>
                  <td>{item.range ? item.range.value : ''}</td>
                  <td>{item.acceleration ? item.acceleration.value : ''}</td>
                  <td>{item.topSpeed ? item.topSpeed.value : ''}</td>
                  <td>{item.bhp ? item.bhp.value : ''}</td>
                  <td>{item.price ? `£${item.price.value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : ''}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Table>
      </div>
    );
  }

  return (
    <div className="polaris__content polaris__simple-grid--full -body-copy">
      <Table
        mode="stack"
        extraClassNames={{
          '-specs': true,
        }}
      >
        <table>
          <caption>{name}</caption>
          <thead>
            <tr>
              <th>MODEL</th>
              <th>FUEL</th>
              <th>MPG</th>
              <th>CO2</th>
              <th>0-62MPH</th>
              <th>TOP SPEED</th>
              <th>BHP</th>
              <th>PRICE</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, itemSpecIndex) => (
              <tr key={`itemSpec_${itemSpecIndex}`}>
                <td><Link href={item.path} extraClassNames={{ 'polaris__car-name--link': true }}>{item.name}</Link></td>
                <td>{item.fuel.value}</td>
                <td>{item.mpg.value}</td>
                <td>{item.co2.value}</td>
                <td>{item.acceleration.value}</td>
                <td>{item.topSpeed.value}</td>
                <td>{item.bhp ? item.bhp.value : ''}</td>
                <td>{item.price && item.price.value ? `£${item.price.value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : ''}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Table>
    </div>
  );
};

const SpecList = ({
  vehicleSpecList,
}) => {
  const specListWithAds = injectionEngine({
    body: vehicleSpecList,
    rules,
  });

  return (
    <>
      {specListWithAds.map((listItem, idx) => (
        listItem.type === 'DESKTOP_INLINE_ADVERT'
          ? (
            <Ad
              id={`refresh-inline_${idx + 1}`}
              isSpaceReserved
              key={`inline_${idx + 1}`}
              type="slot-1"
            />
          )
          : (
            <div className="polaris__content polaris__simple-grid--full -body-copy" key={`specListItem_${idx + 1}`}>
              <SpecListItem
                listItem={listItem}
                idx={idx}
              />
            </div>
          )
      ))}
    </>
  );
};

SpecListItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  listItem: PropTypes.object.isRequired,
};

SpecList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  vehicleSpecList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default SpecList;
