import React from 'react';
import uniqid from 'uniqid';

import { HtmlSafe } from 'polaris-coreweb/exports';
import { getArticleContent } from 'SharedPartialsLocal/getArticleContent'; // no components

import { Ad } from '@autovia-uk/polaris-components/components/atoms/Ad';
import { getNativeAd } from 'SharedPartials/getNativeAd';
import { ArticleGroup } from '@autovia-uk/polaris-components/components/organisms/ArticleGroup';
import { getNewsletter } from 'SharedPartialsLocal/getNewsletter';
import { getSubscription } from 'SharedPartialsLocal/getSubscription';

export const getHomeArticleGroup = (contentData) => {
  if (contentData === null || typeof contentData === 'undefined') {
    return null;
  }

  const {
    layoutType,
    type,
    content,
    ctaLabel,
    clientLogos,
  } = contentData;

  let {
    title,
    ctaUrl,
  } = contentData;

  const contentItems = content.map(getArticleContent);

  let articleCardStyle = 'default';
  let articleCardProps = {
    excerpt: null,
    datePosition: 'meta',
    showRating: true,
    ratingPosition: 'after-title',
  };
  let extraClassNames = {};
  let articlesPerRow = 5;
  let showIndex = false;
  let hideImageMinimalIndex = 4;

  switch (layoutType) {
    case 'Hero Feature':
      articleCardStyle = 'featured';
      articlesPerRow = 3;
      if (contentItems[0]) {
        contentItems[0].image.lazyLoad = false;
        contentItems[0].image.isLCPCandidate = true;
      }
      break;
    case 'Hero List':
      title = 'Latest';
      articlesPerRow = 1;
      ctaUrl = null;
      articleCardProps = {
        excerpt: null,
        datePosition: 'meta',
        image: null,
        showRating: true,
        ratingPosition: 'after-title',
      };
      extraClassNames = {
        '-latest-featured': true,
      };
      break;
    case 'Row':
      extraClassNames = {
        '-home-bottom-articles': true,
      };
      break;
    case 'Double List':
      articlesPerRow = 1;
      articleCardStyle = 'side';
      articleCardProps = {
        datePosition: 'meta',
        showRating: true,
        ratingPosition: 'after-title',
      };
      extraClassNames = {
        '-alt-header': true,
        '-alt-side': true,
      };
      break;
    case 'Feature and Short List':
      articleCardStyle = 'side';
      extraClassNames = {
        '-alt-featured': true,
      };
      break;
    default:
      break;
  }

  switch (type) {
    case 'mostPopular':
      title = 'Most Popular';
      showIndex = true;
      extraClassNames['polaris__home--popular'] = true;
      articleCardStyle = 'minimal';
      hideImageMinimalIndex = 4;
      break;
    default:
      break;
  }

  if (title === 'Resources') {
    extraClassNames['-resources'] = true;
  }

  let image = {};
  if (clientLogos && clientLogos.length && Array.isArray(clientLogos) && clientLogos[0].src) {
    image = {
      src: clientLogos[0].src,
      alt: clientLogos[0].alt || '',
    };
    extraClassNames['-is-sponsored'] = true;
  }

  return (
    <ArticleGroup
      key={uniqid()}
      title={title || ''}
      articlesPerRow={articlesPerRow}
      articleCardStyle={articleCardStyle}
      content={contentItems}
      articleCardProps={articleCardProps}
      extraClassNames={extraClassNames}
      showIndex={showIndex}
      hideImageMinimalIndex={hideImageMinimalIndex}
      ctaLabel={ctaLabel}
      ctaUrl={ctaUrl}
      image={image}
    />
  );
};

export const getHomeContentBlocks = (associatedContent, config) => {
  const {
    promoBoxConfig,
    newsletterConfig,
  } = config;

  if (!associatedContent || !associatedContent.length
    || !Array.isArray(associatedContent)) {
    return false;
  }

  let isFirstRow = false;
  const components = [];
  let adIndex = 1;
  for (let i = 0; i < associatedContent.length; i += 1) {
    const { layoutType, type } = associatedContent[i];

    // assign the class used for the first block in the homepage blocks
    const firstBlockClass = i === 0 ? ' -firstBlock' : '';

    if (layoutType === 'Hero Feature') {
      const j = i + 1;
      components.push((
        <div className={`polaris__core-content polaris__content${firstBlockClass}`}>
          <div className="polaris__grid -with-columns-2-1">
            {getHomeArticleGroup(associatedContent[i])}
            {associatedContent[j]
            && getHomeArticleGroup(associatedContent[j])}
          </div>
        </div>
      ));
      if (associatedContent[j]) {
        i += 1;
      }
    } else if (layoutType === 'Double List') {
      const j = i + 1;

      components.push((
        <div className={`polaris__core-content polaris__content${firstBlockClass}`}>
          <div className="polaris__grid -with-columns-2">
            {getHomeArticleGroup(associatedContent[i])}
            {getHomeArticleGroup(associatedContent[j])}
          </div>
        </div>
      ));
      if (associatedContent[j]) {
        i += 1;
      }
    } else if (type === 'adBlock') {
      // if first block in the homepage blocks is advert, assign the class used for the below_header ad
      const topAdClass = i === 0 ? ' polaris__below-header-ad-wrapper' : '';
      components.push((
        <div className={`polaris__content -homepageAdBlock -homepageAdBlock-${adIndex}${topAdClass}`} key={`adBlock-${adIndex}`}>
          <Ad
            id={`refresh-inline_${adIndex}`}
            isSkippable
            isSpaceReserved
            targeting={{
              position: `inline_${adIndex}`,
              placement: `inline_${adIndex}`,
              refresh: 'yes',
            }}
            type="slot-1"
          />
        </div>
      ));
      adIndex += 1;
    } else if (type === 'newsLetter') {
      if (newsletterConfig.extraClassNames && newsletterConfig.extraClassNames instanceof Object) {
        newsletterConfig.extraClassNames['-home'] = true;
      } else {
        newsletterConfig.extraClassNames = { '-home': true };
      }

      components.push((
        <div className="polaris__content polaris__core-content">
          <div className="polaris__grid -with-columns-2 polaris__subscription-newsletter--grid">
            {promoBoxConfig && getSubscription(promoBoxConfig)}
            {getNewsletter(newsletterConfig)}
          </div>
        </div>
      ));
    } else if (type === 'widgetBlock') {
      const { html } = associatedContent[i];

      if (html) {
        components.push((
          <HtmlSafe
            html={html}
          />
        ));
      }
    } else if (layoutType === 'Row' && !isFirstRow) {
      // Add Native Ad only for the first block layoutType Row in the associatedContent array
      isFirstRow = true;

      const nativeAd = {
        component: [
          <React.Fragment key="native_home">
            {getNativeAd({
              id: 'native_home',
              targeting: {
                position: 'native_home',
                placement: 'native_home',
                refresh: 'no',
              },
            })}
          </React.Fragment>,
        ],
      };

      const injectElement = (obj, index, item) => {
        const content = [...obj.content].slice(0, 4);
        content.splice(index, 0, item);
        return content;
      };
      const associatedContentWithNativeAd = { ...associatedContent[i], content: injectElement(associatedContent[i], 4, nativeAd) };

      components.push((
        <div className={`polaris__core-content polaris__content -has-native-ad${firstBlockClass}`}>
          {getHomeArticleGroup(associatedContentWithNativeAd)}
        </div>
      ));
    } else {
      components.push((
        <div className={`polaris__core-content polaris__content${firstBlockClass}`}>
          {getHomeArticleGroup(associatedContent[i])}
        </div>
      ));
    }
  }

  return components;
};
