import React from 'react';
import PropTypes from 'prop-types';

import { compose } from 'recompose';

import {
  withHelmet,
  withTemplate,
} from 'Protons';

import {
  getArticleGroupContent,
  getRightSideAdConfig,
} from 'Helpers';
import { getPromoBoxSettings } from 'SharedPartialsLocal/getPromoBoxSettings'; // settings, no components

import { Ad } from '@autovia-uk/polaris-components/components/atoms/Ad';
import { getNativeAd } from 'SharedPartials/getNativeAd';
import { getBreadcrumbs } from 'SharedPartialsLocal/getBreadcrumbs';
import { Heading } from '@autovia-uk/polaris-components/components/molecules/Heading';
import { getArticleGroup } from 'SharedPartialsLocal/getArticleGroup';
import { getBody } from 'SharedPartialsLocal/getBody';
import { getNewsletter } from 'SharedPartialsLocal/getNewsletter';
import { getSubscription } from 'SharedPartialsLocal/getSubscription';

const BasicPage = ({
  layoutData: {
    page: {
      layoutType,
      associatedContent,
      body,
      breadcrumbs,
      fullWidth,
      kicker,
      title,
      subtitle,
      isSponsored,
      sponsor: sponsorInfo,
      hideTitle,
    },
  },
  config: {
    globalSettings: {
      promoBoxSettings,
    },
    newsletterConfig,
    breakpoints,
  },
}) => {
  const isFullWidth = fullWidth;
  const isLandingPage = layoutType === 'landingPage';

  let extraClasses = [];
  const bodyExtended = body.map((m) => {
    if (m.type && m.type === 'NEW_CAR_DEALS') {
      extraClasses = [...extraClasses, `-has-embed__${m.type.trim().toLowerCase()}`];
    }

    if (m.__typename && m.__typename === 'ValuationsBlock') {
      extraClasses = [...extraClasses, `-has-embed__${m.__typename.trim().toLowerCase()}`];
    }

    if (m.placeholder && m.placeholder === 'VALUATION_TOOL_WIDGET') {
      extraClasses = [...extraClasses, `-has-embed__${m.placeholder.trim().toLowerCase()}`];
    }

    return { ...m, isSponsored, sponsorInfo };
  });

  // remove duplicates, and convert array in space separated string
  extraClasses = [...new Set(extraClasses)].join(' ');

  const mobileSize = breakpoints?.mobile[0]?.max;
  let isMobile = false;
  if (typeof window !== 'undefined') isMobile = window?.innerWidth <= mobileSize;
  return (
    <>
      <div className={`polaris__body ${extraClasses}`}>
        <main className="polaris__main" id="main">
          {!isFullWidth && !isLandingPage && (
            <div className="polaris__content polaris__heading-block">
              <div className="polaris__heading-block--inner">
                {getBreadcrumbs(breadcrumbs, false, kicker)}
              </div>
            </div>
          )}

          <div className={`polaris__content  ${isFullWidth || isLandingPage ? '-body-full-width' : 'polaris__simple-grid'} -body-copy`}>
            <div className="polaris__simple-grid--main">

              <div className="polaris__text--container">
                {!isFullWidth && !isLandingPage && subtitle && (
                  <Heading size={2} extraClassNames={{ 'polaris__article--subtitle': true }}>
                    {subtitle}
                  </Heading>
                )}
                {isFullWidth && title && !hideTitle && (
                  <Heading size={2} extraClassNames={{ 'polaris__article--subtitle': true }}>
                    {title}
                  </Heading>
                )}
                {getBody({
                  bodyInjected: bodyExtended,
                  template: 'page',
                  layoutType,
                  isMobile,
                })}
              </div>

              {!isFullWidth && !isLandingPage && (
                <div className="polaris__grid -with-columns-2 -inline-subscription-newsletter">
                  {getSubscription(
                    getPromoBoxSettings({
                      promoBoxSettings,
                      ppc: promoBoxSettings.subscriptionArticle,
                      mainTitle: 'Subscribe',
                      image: null,
                      extraClassNames: {
                        '-enhanced': true,
                      },
                    }),
                  )}
                  {getNewsletter({
                    ...newsletterConfig,
                    extraClassNames: {
                      '-article-side': true,
                    },
                  })}
                </div>
              )}
            </div>

            {!isFullWidth && !isLandingPage && (
              <div className="polaris__ad-sidebar polaris__simple-grid--aside">
                <Ad {...getRightSideAdConfig()} />
              </div>
            )}
          </div>

          {!isFullWidth && !isLandingPage && (
            <div className="polaris__content polaris__related-content">
              {getArticleGroup({
                title: 'Most Popular',
                content: getArticleGroupContent(associatedContent, 'mostPopular', {
                  component: [
                    <React.Fragment key="native_article">
                      {getNativeAd({
                        id: 'native_article',
                        targeting: {
                          position: 'native_article',
                          placement: 'native_article',
                          refresh: 'no',
                        },
                      })}
                    </React.Fragment>,
                  ],
                }),
                extraClassNames: { 'polaris__article--most-popular': true },
                articleCardProps: { isHeading: false },
              })}
            </div>
          )}
        </main>
      </div>
    </>
  );
};

BasicPage.propTypes = {
  layoutData: PropTypes.shape({
    page: PropTypes.objectOf({}).isRequired,
  }).isRequired,
  metaData: PropTypes.shape({
    pageTitle: PropTypes.string.isRequired,
  }).isRequired,
  config: PropTypes.shape({
    globalSettings: PropTypes.shape({
      promoBoxSettings: PropTypes.objectOf(
        PropTypes.oneOf([
          PropTypes.string,
          PropTypes.object,
        ]),
      ).isRequired,
    }).isRequired,
    newsletterConfig: PropTypes.shape(),
    breakpoints: PropTypes.shape({
      desktop: PropTypes.arrayOf(
        PropTypes.shape({
          max: PropTypes.number,
          min: PropTypes.number,
        }),
      ),
      mobile: PropTypes.arrayOf(
        PropTypes.shape({
          max: PropTypes.number,
          min: PropTypes.number,
        }),
      ),
    }),
  }).isRequired,
  layoutType: PropTypes.string,
};

BasicPage.defaultProps = {
  layoutType: '',
};

export default compose(
  withTemplate,
  withHelmet('page'),
)(BasicPage);
