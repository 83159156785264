import React from 'react';
import PropTypes from 'prop-types';

import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';

import valuationToolWidgetData from '../Data/index.json';

import './_style.scss';

const Pros = (
  props,
) => {
  const {
    context: {
      cssPrefix,
    },
  } = props;

  const { prosCopy } = valuationToolWidgetData;

  return (
    <div className={`${cssPrefix}__valuation--pros-container`}>
      <div className={`${cssPrefix}__valuation--pros`}>
        <div className={`${cssPrefix}__valuation--pros-title`}>{prosCopy.title}</div>
        <div className={`${cssPrefix}__valuation--pros-content`}>
          { prosCopy.content && prosCopy.content.map(({ title, text }, index) => (
            <div className={`${cssPrefix}__valuation--pros--card`} key={index}>
              <div className={`${cssPrefix}__valuation--pros--card-title`}>{title}</div>
              <div className={`${cssPrefix}__valuation--pros--card-content`}>{text}</div>
            </div>
          )) }
        </div>
      </div>
    </div>
  );
};

Pros.propTypes = {
  context: PropTypes.shape(),
};

Pros.defaultProps = {
  context: {},
};

export default withPolaris(Pros);
