import React, { Fragment } from 'react';
import he from 'he';
import { processNodes } from 'react-html-parser';
import uniqid from 'uniqid';

import { HtmlSafe } from 'polaris-coreweb/exports';
import { ViewportChecker } from '@autovia-uk/polaris-components/components/protons/ViewportChecker';

import { Ad } from '@autovia-uk/polaris-components/components/atoms/Ad'; // Link
import { Ceros } from '@autovia-uk/polaris-components/components/atoms/Ceros'; // no other component imports
import { CarwowValuationWidget } from '@autovia-uk/polaris-components/components/atoms/CarwowValuationWidget'; // IFrame
import { IFrame } from '@autovia-uk/polaris-components/components/atoms/IFrame'; // IFrame
import { Image } from '@autovia-uk/polaris-components/components/atoms/Image'; // no other component imports
import FAQsWidget, { FAQsWidgetId } from 'Organisms/FAQs/FAQsWidget'; // imports image and Organisms/Modal (custom component)
import { Text } from '@autovia-uk/polaris-components/components/atoms/Text'; // no other component imports
import { ShopWindow } from '@autovia-uk/polaris-components/components/atoms/ShopWindow'; // no other component imports
import { getDigiteka } from 'SharedPartials/getDigiteka'; // Digiteka
import { Heading } from '@autovia-uk/polaris-components/components/molecules/Heading'; // Link
import { AccordionFAQs } from '@autovia-uk/polaris-components/components/organisms/AccordionFAQs'; // Accordion (Collapsible: Icon, Heading))
import { Link } from '@autovia-uk/polaris-components/components/molecules/Link'; // no other component imports
import { List } from '@autovia-uk/polaris-components/components/molecules/List'; // ListLayout
import { getPodcast } from 'SharedPartialsLocal/getPodcast'; // Podcast
import { RelatedLinks } from '@autovia-uk/polaris-components/components/molecules/RelatedLinks'; // Image, Link
import { SideContent } from '@autovia-uk/polaris-components/components/molecules/SideContent'; // Heading
import { SocialEmbed } from '@autovia-uk/polaris-components/components/molecules/SocialEmbed'; // Video
import { Video } from '@autovia-uk/polaris-components/components/molecules/Video'; // Video
import { EnhancedIndexBlock } from '@autovia-uk/polaris-components/components/organisms/EnhancedIndexBlock'; // ArticleGroup
import { Gallery } from '@autovia-uk/polaris-components/components/organisms/Gallery'; // Button, Image, Link, Video
import { getNewsletter } from 'SharedPartialsLocal/getNewsletter'; // Newsletter: Button, Image, Input, Form, Heading, Label, Link, Modal
import { getReviewList } from 'SharedPartialsLocal/getReviewList'; // has Heading, Rating, Review (Image, Heading, Link, Rating)
import SellMyCarWidget, { SellMyCarData, SellMyCarWidgetId } from 'Organisms/SellMyCar/LandingPage';
import { getPromoBoxSettings } from 'SharedPartialsLocal/getPromoBoxSettings'; // just data, no components
import { getSubscription } from 'SharedPartialsLocal/getSubscription'; // Subscription: Image, Heading, Link
import { NewCarDealWidget } from 'Organisms/NewCarDealWidget/NewCarDealWidget'; // Loading (then: Image, Button, getBody,
import { Table } from '@autovia-uk/polaris-components/components/organisms/Table'; // no other component imports
import ValuationToolWidget from 'Organisms/ValuationToolWidget/ValuationToolWidget'; // @TODO Why we are not using the component from the CL?

const getClasses = (classesStr) => {
  if (!classesStr) {
    return {};
  }

  return classesStr
    .split(' ')
    .reduce((acc, next) => ({ ...acc, [next]: true }), {});
};

// count inline ads
const adCounter = {
  mobileAdCounter: 0,
  desktopAdCounter: 0,
};

// count ads in the right-hand column
let adCounterRight = 0;

// eslint-disable-next-line consistent-return
export const getComponentForBlockType = (options) => {
  const {
    type,
    block,
    data,
    index,
    embedTypes,
    mappedContentType,
    template,
    layoutType,
  } = options;

  const props = {
    key: `body_block_${index}`,
    mappedContentType,
  };

  const transforms = {
    /* eslint-disable react/prop-types */
    a: ({
      attribs: {
        id,
        href,
        name,
        rel,
        target,
        extraClassNames,
      },
      attribs: allAttributes,
      children,
    }) => {
      const classes = getClasses(allAttributes.class);

      return (
        <Link
          href={href}
          id={id}
          key={uniqid('link-')}
          name={name}
          rel={rel}
          target={target}
          extraClassNames={{
            ...extraClassNames,
            ...classes,
          }}
        >
          {processNodes(children)}
        </Link>
      );
    },
    /* eslint-enable */
  };

  switch (type) {
    case 'TEXT': {
      return (
        <Text
          {...props}
          span={false}
        >
          <HtmlSafe
            html={he.decode(data)}
            transforms={transforms}
          />
        </Text>
      );
    }

    case 'CEROS': {
      return (
        <Ceros
          html={he.decode(data)}
          key={`ceros-${index}`}
        />
      );
    }

    case 'HEADER': {
      const { size, text } = data;
      const headingSize = Number.parseInt(size, 10) || 3;

      return (
        <Heading
          {...props}
          size={headingSize}
        >
          {he.decode(text)}
        </Heading>
      );
    }

    case 'IFRAME': {
      return (
        <IFrame
          key={uniqid()}
          iframeUrl={data.src}
          iframeHeight={data.height}
          iframeWidth={data.width}
          scrolling={data.scrolling}
        />
      );
    }

    case 'IMAGE': {
      props.credits = data.credit;

      // set correct image size for resource download
      props.size = 'content-image';
      if (template === 'resource-download') {
        props.size = 'resource-image';
      }

      return (
        <Image
          {...data}
          {...props}
          displayImageMetadata
        />
      );
    }

    case 'TABLE': {
      return (
        <Table
          {...props}
        >
          <HtmlSafe html={he.decode(he.escape(data))} />
        </Table>
      );
    }

    case 'SUBSCRIBE': {
      const {
        promoBoxSettings,
        breakpoints: {
          desktop: desktopBreakpoint,
          mobile: mobileBreakpoint,
        }, viewport,
      } = block.props;

      const subscriptionBlock = (
        promoBoxSettings && getSubscription(getPromoBoxSettings({
          promoBoxSettings,
          ppc: promoBoxSettings.subscriptionArticle,
        }))
      );

      return (
        <ViewportChecker
          range={viewport === 'desktop' ? desktopBreakpoint : mobileBreakpoint}
          key={uniqid('viewportchecker-')}
        >
          {subscriptionBlock}
        </ViewportChecker>
      );
    }

    case 'NEWSLETTER': {
      const {
        breakpoints: {
          desktop: desktopBreakpoint,
          mobile: mobileBreakpoint,
        }, viewport,
      } = block.props;

      return (
        <ViewportChecker
          range={viewport === 'desktop' ? desktopBreakpoint : mobileBreakpoint}
          key={uniqid('viewportchecker-')}
        >
          {getNewsletter({
            ...block.props,
          })}
        </ViewportChecker>
      );
    }

    case 'SHOPWINDOW': {
      const {
        props: {
          viewport,
        },
      } = block;

      if (viewport === 'mobile') return '';

      return (
        <ShopWindow />
      );
    }

    case 'SUBSCRIBE_NEWSLETTER': {
      const {
        promoBoxSettings,
        breakpoints: {
          desktop: desktopBreakpoint,
          mobile: mobileBreakpoint,
        }, viewport,
      } = block.props;

      const subscriptionBlock = (
        promoBoxSettings && getSubscription(getPromoBoxSettings({
          promoBoxSettings,
          ppc: promoBoxSettings.subscriptionArticle,
          mainTitle: 'Subscribe',
          image: null,
          extraClassNames: {
            '-enhanced': true,
          },
        }))
      );

      return (
        <ViewportChecker
          range={viewport === 'desktop' ? desktopBreakpoint : mobileBreakpoint}
          key={uniqid('viewportchecker-')}
        >
          <div
            className="polaris__grid -with-columns-2 -inline-subscription-newsletter"
            key={uniqid('subscription-newsletter-')}
          >
            {subscriptionBlock}
            {getNewsletter({
              ...block.props.newsletterConfig,
              extraClassNames: {
                '-article-side': true,
              },
            })}
          </div>
        </ViewportChecker>
      );
    }

    case 'SOCIAL_EMBED': {
      if (!data.html) {
        return false;
      }

      // Check for YouTube video
      if (data.provider_name === 'YouTube' && data.__data && data.__data.url) {
        return (
          <Video url={data.__data.url} key={uniqid()} />
        );
      }

      // Check for SoundCloud
      if (data.provider_name === 'SoundCloud') {
        // get iframe src
        const iframeURLRegex = new RegExp('src="(.*?)"', 'gm');
        const iframeURLMatch = iframeURLRegex.exec(data.html);

        if (!iframeURLMatch || !iframeURLMatch[1]) {
          return false;
        }

        const podCastData = {
          podcastUrl: iframeURLMatch[1],
        };

        return getPodcast(podCastData);
      }

      embedTypes.add(data.provider_name);

      return (
        <SocialEmbed
          {...props}
          type={data.provider_name}
          url={data.__data.url}
        >
          <HtmlSafe
            html={data.html}
          />
        </SocialEmbed>
      );
    }

    case 'RELATED_CONTENT': {
      const blockData = JSON.parse(block.data);
      let componentData = {};
      if (blockData && blockData.length && Array.isArray(blockData)) {
        componentData = blockData.map((bd) => {
          const img = bd.image || null;
          if (img) {
            img.size = 'related-embed';
          }
          return (
            {
              url: bd.url || '',
              label: bd.label || '',
              image: img,
            }
          );
        });
      }
      return (
        <RelatedLinks
          content={componentData}
          {...props}
        />
      );
    }

    case 'IndexBlock': {
      return (
        <List
          content={block.items}
          {...props}
        />
      );
    }

    case 'ENHANCED_INDEX_BLOCK': {
      return (
        <EnhancedIndexBlock
          {...props}
          title={block.title}
          subtitle={block.subtitle}
          content={block.teasers}
          articleCardProps={{
            datePosition: 'meta',
          }}
        />
      );
    }

    case 'SIDE_CONTENT': {
      const sideContenData = block && block.props && Array.isArray(block.props) && block.props[0];
      const associatedContent = sideContenData && sideContenData.children;
      const isArray = Array.isArray(associatedContent);

      if (!isArray || (isArray && associatedContent.length === 0)) return false;

      return (
        <SideContent
          key={uniqid()}
          title={sideContenData.title}
          titleSize={4}
        >
          {associatedContent.map((child, iterator) => (
            <Fragment key={`sideContent_${iterator}`}>
              <Heading
                size={5}
              >
                {child.title}
              </Heading>
              <ul className="polaris__list">
                {child.items.map((item, listIterator) => <li key={`sideContentItem_${listIterator}`}><a href={item.url}>{item.title}</a></li>)}
              </ul>
            </Fragment>
          ))}
        </SideContent>
      );
    }

    case 'DESKTOP_SIDE_ADVERT': {
      if (layoutType === 'adFreeTemplate') return null;
      if (layoutType === 'commercialPage') return null;
      if (layoutType === 'commercialPageTitleImage') return null;

      adCounterRight += 1;

      return (
        <Ad
          {...block.props}
          {...props}
          isSkippable
        />
      );
    }

    case 'DESKTOP_INLINE_ADVERT':
    case 'MOBILE_INLINE_ADVERT': {
      if (layoutType === 'adFreeTemplate') return null;
      if (layoutType === 'commercialPage') return null;
      if (layoutType === 'commercialPageTitleImage') return null;

      const platform = type === 'DESKTOP_INLINE_ADVERT' ? 'desktop' : 'mobile';

      let platformAdCounter = 0;
      if (platform === 'desktop') {
        adCounter.desktopAdCounter += 1;
        platformAdCounter = adCounter.desktopAdCounter;
      } else {
        adCounter.mobileAdCounter += 1;
        platformAdCounter = adCounter.mobileAdCounter;
      }

      return (
        <>
          <Ad
            {...block.props}
            {...props}
            extraClassNames={{
              ...block.props.extraClassNames,
              'hide-desktop': platform === 'mobile',
              'hide-tablet': platform === 'desktop',
              'hide-mobile': platform === 'desktop',
            }}
            isSkippable={platform === 'desktop'}
            isSpaceReserved
            targetViewport={platform}
          />
        </>

      );
    }

    case 'TEADS': {
      if (layoutType === 'adFreeTemplate') return null;
      if (layoutType === 'commercialPage') return null;
      if (layoutType === 'commercialPageTitleImage') return null;

      return (
        <Ad
          {...block.props}
          {...props}
          isTeads
        />
      );
    }

    case 'DIGITEKA': {
      if (layoutType === 'adFreeTemplate') return null;
      if (layoutType === 'commercialPage') return null;
      if (layoutType === 'commercialPageTitleImage') return null;

      return (
        getDigiteka({
          ...block.props,
          ...props,
        })
      );
    }

    case 'ItemBlock': {
      switch (block.itemType) {
        case 'GALLERY_SLIDESHOW':
        case 'GALLERY_LINKED': {
          const media = block.item.images.filter(i => i.__typename !== 'LinkedMedia');
          return (
            <Gallery
              mode={block.itemType === 'GALLERY_SLIDESHOW' ? 'swipe' : 'linkout'}
              cover={block.item.teaserImage}
              url={block.item.url}
              media={media}
              viewAllLabel="View Gallery"
              viewAllUrl={block.item.url}
              {...props}
            />
          );
        }
        default: return null;
      }
    }

    case 'REVIEW': {
      const { rating, header, subtitle } = data;
      return getReviewList(
        header,
        subtitle,
        parseFloat(rating),
        { '-inline': true },
      );
    }

    case 'NEW_CAR_DEALS': {
      const blockData = JSON.parse(block.data);

      return (
        <NewCarDealWidget
          content={blockData}
          key={index}
        />
      );
    }

    case 'ValuationsBlock': {
      return (
        <ValuationToolWidget
          {...props}
        />
      );
    }

    case 'PlaceholderBlock': {
      const blockData = JSON.parse(block.data);
      const impactUrl = options?.props?.context?.carwow?.valuationWidget?.impactUrl;
      if (block && block.placeholder) {
        switch (block.placeholder) {
          case FAQsWidgetId:
            return <FAQsWidget key={`fAQsWidget_${index}`} data={blockData} />;
          case 'FAQsAccordion':
            return <AccordionFAQs key={`accordionFAQsWidget_${index}`} data={blockData} />;
          case SellMyCarWidgetId:
            return <SellMyCarWidget impactUrl={impactUrl} {...SellMyCarData} />;
          case 'IN_ARTICLE_VALUATION_TOOL':
            return (
              <CarwowValuationWidget impactUrl={impactUrl} key="VALUATION_WIDGET" />
            );
          default:
            return false;
        }
      }

      return false;
    }

    default:
      return false;
  }
};
