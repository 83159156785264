import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';

import { getPromoBoxSettings } from 'SharedPartialsLocal/getPromoBoxSettings';

import {
  getHomeContentBlocks,
} from './homeHelpers';

/** Site specific SASS overrides */
import 'Styles/components/_commons.scss'; // there is only footer there now
import 'Styles/components/molecules/_ArticleCard.scss';
import 'Styles/components/organisms/_ArticleGroup.scss';
import 'Styles/components/templates/_Home.scss';

const Home = ({
  layoutData: { page: { associatedContent } },
  config: {
    newsletterConfig,
    globalSettings: {
      promoBoxSettings,
    },
  },
}) => {
  const config = {
    promoBoxConfig: getPromoBoxSettings({
      promoBoxSettings,
      ppc: promoBoxSettings.subscriptionHomepage,
      mainTitle: 'Subscribe',
      image: null,
      extraClassNames: {
        '-home': true,
      },
    }),
    newsletterConfig,
  };
  const components = getHomeContentBlocks(associatedContent, config);

  return (
    <>
      <div className="polaris__body">
        <main className="polaris__main" id="main">
          {components && components.length && Array.isArray(components) && components.map(component => (
            <React.Fragment key={uniqid()}>
              {component}
            </React.Fragment>
          ))}
        </main>
      </div>
    </>
  );
};

Home.propTypes = {
  layoutData: PropTypes.shape({
    page: PropTypes.objectOf().isRequired,
  }).isRequired,
  config: PropTypes.shape().isRequired,
};
Home.defaultProps = {};

export default Home;
